<template>
  <div>
    <vx-card>
      <vs-row>
        <h4 v-if="!hotelId" class="mb-5">Add Brand</h4>
        <h4 v-if="hotelId" class="mb-5">Edit Brand</h4>
      </vs-row>

      <vs-row vs-w="12">
        <vs-col vs-w="6">
          <vs-row vs-w="12">
            <vs-col vs-w="12" class="mb-3">
              <span class="mb-2 block text-sm">City (*)</span>
              <v-select
                label="city"
                data-vv-as="City"
                name="cityId"
                v-validate="'required'"
                :options="cityOptions"
                :reduce="city => city.id"
                v-model="payload.cityId"
              />
              <span class="block text-sm mt-2 text-danger">{{
                errors.first("cityId")
              }}</span>
            </vs-col>

            <vs-col vs-w="12" class="mb-3">
              <span class="mb-2 block text-sm">Property Type (*)</span>
              <v-select
                label="name"
                data-vv-as="Property Type"
                name="propertyTypeId"
                v-validate="'required'"
                :options="serviceOptions"
                :reduce="service => service.id"
                v-model="payload.propertyTypeId"
              />
              <span class="block text-sm mt-2 text-danger">{{
                errors.first("propertyTypeId")
              }}</span>
            </vs-col>

            <vs-col vs-w="12" class="mb-6">
              <vs-input
                name="name"
                data-vv-as="Brand Name"
                class="w-full"
                v-validate="'required'"
                icon-pack="feather"
                icon="icon-user"
                icon-no-border
                label="Brand Name (*)"
                v-model="payload.name"
              />
              <span class="block text-sm mt-2 text-danger">{{
                errors.first("name")
              }}</span>
            </vs-col>

            <vs-col vs-w="12" class="mb-3">
              <vs-textarea
                name="address"
                data-vv-as="Address"
                class="w-full"
                v-validate="'required'"
                icon-pack="feather"
                icon="icon-user"
                icon-no-border
                label="Address (*)"
                v-model="payload.address"
              />
              <span class="block text-sm mt-2 text-danger">{{
                  errors.first("address")
                }}</span>
            </vs-col>
          </vs-row>
        </vs-col>

  <!--      <vs-col vs-w="6" class="pl-8">-->
  <!--        <div class="flex items-start flex-col sm:flex-row">-->
  <!--          <img :src="url" class="rounded mr-4" width="300" height="300" />-->
  <!--          <div>-->
  <!--            <input-->
  <!--              type="file"-->
  <!--              @change="addImage"-->
  <!--              class="hidden"-->
  <!--              ref="update_logo_input"-->
  <!--              accept="image/*"-->
  <!--            />-->
  <!--            <vs-button-->
  <!--              @click="$refs.update_logo_input.click()"-->
  <!--              class="mr-4 mb-2"-->
  <!--              type="filled"-->
  <!--              icon-pack="feather"-->
  <!--              icon="icon-edit"-->
  <!--            ></vs-button>-->
  <!--            <vs-button-->
  <!--              @click="removeImage"-->
  <!--              type="border"-->
  <!--              color="danger"-->
  <!--              icon-pack="feather"-->
  <!--              icon="icon-trash"-->
  <!--            ></vs-button>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <span class="block text-sm mt-2 text-danger">{{-->
  <!--          errors.first("logo")-->
  <!--        }}</span>-->
  <!--      </vs-col>-->
      </vs-row>

      <vs-row vs-w="12">
        <vs-col vs-w="12" class="mb-3">
          <span class="mb-2 block text-sm">Description</span>
          <input type="file" @change="uploadMedia" id="file" hidden />

  <!--        <validation-provider rules="required"-->
  <!--                             name="Description" v-slot="{ errors }">-->
            <quill-editor
              name="description"
              v-model="payload.description"
              :options="editorOption"
              @ready="onEditorReady($event)"
              ref="myQuillEditor"
            ></quill-editor>
            <span class="block text-sm mt-2 text-danger">{{
                errors.first("description")
            }}</span>
  <!--        </validation-provider>-->
        </vs-col>

        <vs-col vs-w="12" class="mt-3">
          <div class="vx-col w-full" @click.prevent="storeData">
            <vs-button :loading="isSaving" class="mr-3 mb-2">Submit</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <vx-card v-if="hotelId" class="mt-8">
      <vs-row>
        <vs-col>
          <h4 class="mb-5">Active Provider</h4>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col>
          <span class="mb-2 block text-sm">Provider</span>
          <v-select
            label="activeProvider"
            data-vv-as="Active Provider"
            name="activeProvider"
            v-validate="'required'"
            :options="activeProvider.options"
            :reduce="provider => provider"
            v-model="activeProvider.provider"
          />
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-w="12" class="mt-3">
          <div class="vx-col w-full" @click.prevent="storeActiveProvider">
            <vs-button :loading="isSaving" class="mr-3 mb-2">Submit</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <vx-card v-if="hotelId" class="mt-8">
      <vs-row>
        <vs-col>
          <h4 class="mb-5">Markup</h4>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-w="12" class="mb-6">
          <vs-input
            name="vancynetMarkup"
            data-vv-as="Vancynet Markup"
            class="w-full"
            label="Vancynet Markup"
            v-model="markup.vancynet"
          />
          <span class="block text-sm mt-2 text-danger">{{
            errors.first("vancynetMarkup")
          }}</span>
        </vs-col>

        <vs-col vs-w="12" class="mb-6">
          <vs-input
            name="mgMarkup"
            data-vv-as="MG Markup"
            class="w-full"
            label="MG Markup"
            v-model="markup.mg"
          />
          <span class="block text-sm mt-2 text-danger">{{
            errors.first("mgMarkup")
          }}</span>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-w="12" class="mt-3">
          <div class="vx-col w-full" @click.prevent="storeMarkup">
            <vs-button :loading="isSaving" class="mr-3 mb-2">Submit</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <vx-card v-if="hotelId" class="mt-8">
      <vs-row>
        <vs-col>
          <h4 class="mb-5">Provider Code Map</h4>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-w="12" class="mb-6">
          <vs-input
            name="phriCode"
            data-vv-as="PHRI Code"
            class="w-full"
            label="PHRI Code"
            v-model="payloadCodes.phriCode"
          />
          <span class="block text-sm mt-2 text-danger">{{
            errors.first("phriCode")
          }}</span>
        </vs-col>

        <vs-col vs-w="12" class="mb-6">
          <vs-input
            name="mgCode"
            data-vv-as="MG Code"
            class="w-full"
            label="MG Code"
            v-model="payloadCodes.mgCode"
          />
          <span class="block text-sm mt-2 text-danger">{{
            errors.first("mgCode")
          }}</span>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-w="12" class="mt-3">
          <div class="vx-col w-full" @click.prevent="storeCodes">
            <vs-button :loading="isSaving" class="mr-3 mb-2">Submit</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Quill Editor Library
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Quill from "quill";
import ImageResize from "quill-image-resize-module";
import { ImageDrop } from "quill-image-drop-module";
import { quillEditor } from "vue-quill-editor";
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

// Store Module
import moduleBrandManagement from "@/store/brand-management/moduleBrandManagement.js";

export default {
  name: "BrandAdd",

  metaInfo: {
    title: "Add Brand",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect,
    quillEditor
  },

  computed : {
    hotelId() {
      if (this.$route.params.id) {
        return this.$secure.decrypt(this.$route.params.id);
      }
      return null;
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  data: () => ({
    payload: {
      name: '',
      logo: '',
      address: '',
      description: '',
      cityId: '',
      propertyTypeId: '',
      star: null,
      longitude: null,
      latitude: null,
    },
    activeProvider: {
      provider: 'mg',
      options: ['vancynet', 'mg']
    },
    markup: {
      mg: 0,
      vancynet: 0,
    },
    payloadCodes: {
      mgCode: '',
      phriCode: '',
    },
    url: null,
    isSaving: false,

    // options
    serviceOptions: [],
    cityOptions: [],
    editorOption: {
      // some quill options
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
            ["link", "image"]
          ],
          handlers: {
            image: function() {
              document.getElementById("file").click();
            }
          }
        },
        imageResize: {
          modules: ["Resize", "DisplaySize", "Toolbar"]
        },
        imageDrop: true
      }
    }
  }),

  watch: {
    url(url) {
      if (this.url == null) {
        this.url = "https://via.placeholder.com/300";
      } else {
        this.url = url;
      }
    }
  },

  methods: {
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.logo = file;
    },

    removeImage() {
      this.url = null;
      this.payload.logo = "";
    },

    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },

    uploadMedia(e) {
      const file = e.target.files[0];

      const form = new FormData();
      form.append("media", file);

      axios
        .post("brand-media", form, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(({ data: res }) => {
          // set position cursor
          const range = this.editor.getSelection();
          // set image on the server to quill editor
          this.editor.insertEmbed(range.index, "image", res.data);
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    getCities() {
      axios
        .get("/cities")
        .then(({ data: res }) => {
          this.cityOptions = res.data;
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    getCategoryProducts() {
      axios
        .get("/property-types")
        .then(({ data: res }) => {
          this.serviceOptions = res.data;
        })
        .catch(err => this.$catchErrorResponse(err));
    },

    getData() {
      this.$vs.loading({
        type: 'sound'
      })

      this.$store
        .dispatch("brandManagement/fetchBrand", this.hotelId)
        .then(({ data: res }) => {
          Object.assign(this.payload, res.data)

          Object.assign(this.payloadCodes, {
            phriCode: res.data.phriCode == null ? '' : res.data.phriCode,
            mgCode: res.data.mgCode == null ? '' : res.data.mgCode,
          })

          Object.assign(this.activeProvider, {
            provider: res.data.activeProvider == null ? '' : res.data.activeProvider,
            options: ['vancynet', 'mg'],
          })

          Object.assign(this.markup, {
            mg: res.data.mgMarkup == null ? 0 : res.data.mgMarkup,
            vancynet: res.data.vancynetMarkup == null ? 0 : res.data.vancynetMarkup,
          })
        })
        .catch(err => this.$catchErrorResponse(err))
        .finally(res => {
          this.loading = false
          this.$vs.loading.close()
        })
    },

    async storeData() {
      if (!(await this.$validator.validateAll())) {
        return
      }

      // if (this.payload.logo !== "") {
      //   formData.set("logo", this.payload.logo);
      // }

      this.isSaving = true;
      const dispatch = this.hotelId ? 'brandManagement/updateBrand' : 'brandManagement/storeBrand';
      this.$store
        .dispatch(dispatch, this.payload)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/brands");
        })
        .catch(err => {
          this.$catchErrorResponse(err);
        })
        .finally(() => {
          this.isSaving = false;
        })
    },

    async storeCodes() {
      this.isSaving = true;
      this.$store
        .dispatch('brandManagement/updateMapCode', { id: this.hotelId, ...this.payloadCodes })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/brands");
        })
        .catch(err => {
          this.$catchErrorResponse(err);
        })
        .finally(() => {
          this.isSaving = false;
        })
    },

    async storeActiveProvider() {
      this.isSaving = true;
      this.$store
        .dispatch('brandManagement/updateActiveProvider', { id: this.hotelId, activeProvider: this.activeProvider.provider })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/brands");
        })
        .catch(err => {
          this.$catchErrorResponse(err);
        })
        .finally(() => {
          this.isSaving = false;
        })
    },

    async storeMarkup() {
      this.isSaving = true;
      this.$store
        .dispatch('brandManagement/updateMarkup', {
          id: this.hotelId,
          mgMarkup: parseFloat(this.markup.mg),
          vancynetMarkup: parseFloat(this.markup.vancynet),
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/brands");
        })
        .catch(err => {
          this.$catchErrorResponse(err);
        })
        .finally(() => {
          this.isSaving = false;
        })
    },
  },

  mounted() {
    if (this.url == null) {
      this.url = "https://via.placeholder.com/300";
    }
  },

  created() {
    if (!moduleBrandManagement.isRegistered) {
      this.$store.registerModule("brandManagement", moduleBrandManagement);
      moduleBrandManagement.isRegistered = true;
    }

    if (this.hotelId) {
      this.getData();
    }

    this.getCities();
    this.getCategoryProducts();
  }
};
</script>

<style></style>
